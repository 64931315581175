import {
  Button,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { CalendarToday, Schedule } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  ErrorPage,
  getISODate,
  LoadingPage,
  NoAccess,
  Nodata,
  useAuth,
} from "../sdk";
import PaceCalculation from "./components/PaceCalculation";
import {
  Header,
  HeaderCard,
  Options,
  PacePage,
  TableCard,
  ClassWrapper,
  ScheduleIconDisabled,
  Select,
} from "./components/Styles";
import usePace from "./hooks/usePace";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

export const SubHeaderNew = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
`;
export const DATE = styled(Box)`
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  display: flex;
  padding: 0px 12px 0px 16px;
`;

export const PickupLabel = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  letter-spacing: 0px;
  padding-right: 17px;
  padding-left: 8px;
  white-space: nowrap;
`;
export const ViewButton = styled(Button)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  height: 30px;
  text-transform: capitalize;
  color: #000000;
  letter-spacing: 0px;
  padding-right: 10px;
  padding-left: 10px;
  white-space: nowrap;
`;

export const HeaderCardPickup = styled(Box)`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex: 0;
  color: #281e53;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  margin-left: 16px;
  padding-right: 12px;
`;

export const LabelNew = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #281e53;
  padding-right: 22px;
`;

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 98;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;

export const HeaderCardCenterNew = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-left: 16px;
  padding-left: 24px;
  padding-right: 24px;
  height: 32px;
  button.MuiButtonBase-root {
    opacity: 1;
    min-width: 100px;
    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;
    }
  }
`;

const typesOfPickup = [
  { value: "occPickup", name: "Occupancy Pickup" },
  { value: "arrPickup", name: "ADR Pickup" },
  { value: "revPickup", name: "Revenue Pickup" },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Pace({ setPageHeader }) {
  const { hotelId, FROMDATE, TODATE } = useParams();
  const { hotelSelect, authFetch, currentHotel } = useAuth();
  const history = useHistory();
  const { token, permission } = useAuth();
  const [fromDate, setFromDate] = useState(() => {
    if (!isNaN(new Date(FROMDATE).getTime())) {
      const [year, mon, day] = FROMDATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(FROMDATE);
        }
      } else {
        return new Date(new Date().setDate(new Date().getDate() - 1));
      }
    } else {
      return new Date(new Date().setDate(new Date().getDate() - 1));
    }
  });
  const [toDate, setToDate] = useState(() => {
    if (!isNaN(new Date(TODATE).getTime())) {
      const [year, mon, day] = TODATE.split("-");

      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(TODATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const [fromDateFiles, setFromDateFiles] = useState([]);
  const [toDateFiles, setToDateFiles] = useState([]);
  const [fromFileId, setFromFileId] = useState(0);
  const [toFileId, setToFileId] = useState(0);
  const handleChange = (newValue, type = "from") => {
    if (type === "from") {
      setFromFileId(newValue);
    } else {
      setToFileId(newValue);
    }
  };

  let first = getISODate(fromDate).split("T")[0];
  const fromDateDefaultValue = {
    year: parseInt(first?.split("-")[0]),
    month: parseInt(first?.split("-")[1]),
    day: parseInt(first?.split("-")[2]),
  };
  let second = getISODate(toDate).split("T")[0];
  const toDateDefaultValue = {
    year: parseInt(second?.split("-")[0]),
    month: parseInt(second?.split("-")[1]),
    day: parseInt(second?.split("-")[2]),
  };

  const [fromDateNew, setFromDateNew] = useState(fromDateDefaultValue);
  const [toDateNew, settoDateNew] = useState(toDateDefaultValue);

  const [pickup, setPickup] = useState(
    Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24))
  );

  const [selectedPickup, setSelectedPickup] = useState("occPickup");
  let PUReport = null,
    viewAllHotelsPickup = null;
  const {
    monthlyBookings,
    setMonthlyBookings,
    open,
    setOpen,
    setnetworkMsg,
    handleCloseStatus,
    networkMsg,
    Loading,
    Error,
  } = usePace({
    fromDate,
    toDate,
    selectedPickup,
    fromFileId,
    toFileId,
  });

  const [maximumDate, setMaximumDate] = useState(fromDateDefaultValue);
  const maximumToDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [popoverData, setPopOverData] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [remarkData, setRemarkData] = useState([]);

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);
  useEffect(() => {
    setPageHeader("Pick-Up Report");
    return () => {
      setPageHeader("");
    };
  }, []);

  async function getFileTimes(type) {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}//bookings/file/${
        getISODate(type === "from" ? fromDate : toDate).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (type === "from") {
      setFromDateFiles(modifiedDateFiles(data));
      setFromFileId(
        [...data]?.sort(
          (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
        )?.[0]?.id ?? 0
      );
    } else {
      setToDateFiles(modifiedDateFiles(data));
      setToFileId(
        [...data]?.sort(
          (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
        )?.[0]?.id ?? 0
      );
    }
  }

  function modifiedDateFiles(data) {
    if (!data || !data.length) {
      return [];
    }
    return data
      .sort((a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn))
      .map((file) => {
        let hrs = new Date(new Date(file.uploadedOn).getTime()).getHours();
        let mins = new Date(new Date(file.uploadedOn).getTime()).getMinutes();
        if (mins < 10) {
          mins = "0" + mins;
        }
        if (hrs / 12 > 1) {
          return {
            ...file,
            time: (hrs % 12) + ":" + mins + " PM ",
          };
        } else {
          return {
            ...file,
            time: hrs + ":" + mins + " AM ",
          };
        }
      });
  }
  useEffect(() => {
    getFileTimes("from");
  }, [fromDate]);

  useEffect(() => {
    getFileTimes("to");
  }, [toDate]);

  useEffect(() => {
    history.replace(
      `/hotel/${hotelId}/pickup_report/${getISODate(fromDate).split("T")[0]}/${
        getISODate(toDate).split("T")[0]
      }`
    );
  }, [fromDate, toDate, hotelId, history]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "PUReport") {
        PUReport = permission[key];
      }
      if (permission[key].name === "viewAllHotelsPickup") {
        viewAllHotelsPickup = permission[key];
      }
    }
  }
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");
      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });

  const RemarkData = async () => {
    const yyyymmdd = getISODate(fromDate).split("T")[0];
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/remark/${yyyymmdd}`,
    });
    const { data, error } = await get();
    if (data) {
      setRemarkData(data);
      console.log(data, data.length);
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    RemarkData();
  }, [token]);

  const uploadImage = async (images, date) => {
    const UploadSuccess = await Promise.all(
      images.map((file) => {
        return new Promise(async (res) => {
          try {
            if (!(date instanceof Date)) {
              date = new Date(date);
            }
            const yyyymmdd = getISODate(date).split("T")[0];
            const formData = new FormData();
            formData.append("date", yyyymmdd);
            formData.append("image", file);
            const { post } = await authFetch({
              path: `/hotel/${hotelId}/upload-image`,
            });
            const { data, error } = await post(formData);
            if (error) {
              throw new Error("Failed to upload image");
            }
            res({ id: data.id, imageURL: data.imageURL });
          } catch (error) {
            res({ id: "", imageURL: "" });
          }
        });
      })
    );
    return UploadSuccess;
  };

  async function sendRemark(date, popOver) {
    const data = {
      description: popOver?.remark?.remark ?? "",
      imageId: [
        ...(!!selectedImages?.length ? selectedImages : []),
        ...(!!popOver?.images?.length ? popOver?.images : []),
      ].map((item) => item.id),
      date: date,
    };

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/remark`,
    });
    const { data: responseData, error } = await post(data);
    if (data) {
      RemarkData();
      // setnetworkMsg("Upload Successful")
      // setOpen(true);
    }
  }

  useEffect(() => {
    if (toDateNew) {
      let dateNew = new Date(
        [
          toDateNew?.year,
          toDateNew?.month,
          toDateNew?.day.length === 1 ? "0" + toDateNew?.day : toDateNew?.day,
        ].join("/")
      );
      let first = getISODate(
        new Date(dateNew.getTime() - pickup * 24 * 60 * 60 * 1000)
      ).split("T")[0];
      const Value = {
        year: parseInt(first?.split("-")[0]),
        month: parseInt(first?.split("-")[1]),
        day: parseInt(first?.split("-")[2]),
      };
      let checkValue = new Date(
        [
          toDateNew?.year,
          toDateNew?.month,
          toDateNew?.day.length === 1 ? "0" + toDateNew?.day : toDateNew?.day,
        ].join("/")
      );
      let dateCheck = new Date(
        new Date(checkValue).setDate(new Date(checkValue).getDate() - 1)
      );
      let second = getISODate(dateCheck).split("T")[0];

      const fromDateMaximumValue = {
        year: parseInt(second?.split("-")[0]),
        month: parseInt(second?.split("-")[1]),
        day: parseInt(second?.split("-")[2]),
      };
      setToDate(dateNew);
      setFromDate(new Date(dateNew.getTime() - pickup * 24 * 60 * 60 * 1000));
      setMaximumDate(fromDateMaximumValue);
      setFromDateNew(Value);
    }
  }, [toDateNew]);

  useEffect(() => {
    if (fromDateNew) {
      let dateNew = new Date(
        [
          toDateNew?.year,
          toDateNew?.month,
          toDateNew?.day.length === 1 ? "0" + toDateNew?.day : toDateNew?.day,
        ].join("/")
      );

      let datevar = new Date(
        [
          fromDateNew?.year,
          fromDateNew?.month,
          fromDateNew?.day.length === 1
            ? "0" + fromDateNew?.day
            : fromDateNew?.day,
        ].join("/")
      );
      const diffDays = Math.round((dateNew - datevar) / (1000 * 60 * 60 * 24));

      setPickup(diffDays);
      setFromDate(datevar);
    }
  }, [fromDateNew]);
  function handlePickup(value) {
    if (value === "") {
      setPickup(value);
      setFromDate(
        new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 1)
      );

      return;
    }
    if (value < 0) {
      value = value * -1;
    }
    if (value === "0" || value === "-0") {
      value = 1;
    }
    setPickup(value);
    setFromDate(new Date(toDate.getTime() - value * 24 * 60 * 60 * 1000));
    let first = getISODate(
      new Date(toDate.getTime() - value * 24 * 60 * 60 * 1000)
    ).split("T")[0];
    const Value = {
      year: parseInt(first?.split("-")[0]),
      month: parseInt(first?.split("-")[1]),
      day: parseInt(first?.split("-")[2]),
    };
    setFromDateNew(Value);
  }

  const formatInputValue = () => {
    if (fromDateNew) {
      const value =
        `${fromDateNew.day < 10 ? "0" + fromDateNew.day : fromDateNew.day}` +
        "/" +
        `${
          fromDateNew.month < 10 ? "0" + fromDateNew.month : fromDateNew.month
        }` +
        "/" +
        `${fromDateNew.year}`;
      return value;
    }
  };

  const formatInputValueToDate = () => {
    if (toDateNew) {
      const value =
        `${toDateNew.day < 10 ? "0" + toDateNew.day : toDateNew.day}` +
        "/" +
        `${toDateNew.month < 10 ? "0" + toDateNew.month : toDateNew.month}` +
        "/" +
        `${toDateNew.year}`;
      return value;
    }
  };
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );

  const renderCustomInputToDate = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValueToDate()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "14px",

        borderRadius: "10px",

        color: "#000000",
        outline: "none",
        height: "30px",
        border: "none",
        font: "bold 16px / 20px Roboto",
        cursor: "pointer",
        width: "140px",
        paddingLeft: "14px",
      }}
      className="my-custom-input-class"
    />
  );
  const StyledCalenderWrapper = styled(Box)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));
  console.log({ fromDate, toDate }, "pick");

  return currentHotel?.isPreciumEnabled ? (
    !Error && PUReport ? (
      <PacePage>
        <ClassWrapper>
          <Header>
            <SubHeaderNew>
              <Stack direction={"row"} gap={2}>
                <DATE>
                  <HeaderCard>
                    <LabelNew style={{ paddingRight: "0px" }}>From</LabelNew>
                    <label>
                      <StyledCalenderWrapper>
                        <DatePicker
                          value={fromDateNew}
                          onChange={setFromDateNew}
                          renderInput={renderCustomInput}
                          calendarClassName="myCustomCalendar"
                          maximumDate={maximumToDate}
                        />
                        <CalenderIcon />
                      </StyledCalenderWrapper>
                    </label>
                  </HeaderCard>

                  <HeaderCardCenterNew>
                    {fromDateFiles.length > 0 ? (
                      <Select
                        disableUnderline
                        MenuProps={{
                          classes: {
                            paper: "dropdownStyle",
                          },
                        }}
                        variant="standard"
                        IconComponent="none"
                        inputProps={{
                          classes: {
                            icon: "icon",
                          },
                        }}
                        value={fromFileId}
                        onChange={(e) => handleChange(e.target.value, "from")}
                      >
                        {fromDateFiles.map((file, index) => (
                          <MenuItem value={file.id} key={index}>
                            <Tab
                              style={{
                                marginRight: "10px",
                              }}
                              label={file.time}
                              value={file.id}
                              {...a11yProps(file.id)}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Box style={{ width: "56px" }}></Box>
                    )}
                    {fromDateFiles.length > 0 ? (
                      <Schedule
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#306FBC",
                          marginLeft: "-42px",
                        }}
                      />
                    ) : (
                      <ScheduleIconDisabled
                        style={{
                          color: "#6f6f6f",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                  </HeaderCardCenterNew>

                  <HeaderCard>
                    <LabelNew>To</LabelNew>
                    <label>
                      <StyledCalenderWrapper>
                        <DatePicker
                          value={toDateNew}
                          onChange={settoDateNew}
                          renderInput={renderCustomInputToDate}
                          calendarClassName="myCustomCalendar"
                          maximumDate={maximumToDate}
                        />
                        <CalenderIcon />
                      </StyledCalenderWrapper>
                    </label>
                  </HeaderCard>
                  <HeaderCardCenterNew>
                    {toDateFiles.length > 0 ? (
                      <Select
                        disableUnderline
                        MenuProps={{
                          classes: {
                            paper: "dropdownStyle",
                          },
                        }}
                        variant="standard"
                        IconComponent="none"
                        inputProps={{
                          classes: {
                            icon: "icon",
                          },
                        }}
                        value={toFileId}
                        onChange={(e) => handleChange(e.target.value, "to")}
                      >
                        {toDateFiles.map((file, index) => (
                          <MenuItem value={file.id} key={index}>
                            <Tab
                              style={{
                                marginRight: "10px",
                              }}
                              label={file.time}
                              value={file.id}
                              {...a11yProps(file.id)}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Box style={{ width: "56px" }}></Box>
                    )}

                    {toDateFiles.length > 0 ? (
                      <Schedule
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#306FBC",
                          marginLeft: "-42px",
                        }}
                      />
                    ) : (
                      <ScheduleIconDisabled
                        style={{
                          color: "#6f6f6f",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    )}
                  </HeaderCardCenterNew>
                </DATE>
                <HeaderCardPickup>
                  <PickupLabel>Pickup Days</PickupLabel>
                  <TextField
                    type="number"
                    variant="standard"
                    value={pickup}
                    onChange={(e) => {
                      handlePickup(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "54px" }}
                    InputProps={{
                      className: "input",
                      disableUnderline: true,
                      inputProps: { min: 1, max: 100 },
                    }}
                  />
                </HeaderCardPickup>
                <HeaderCard
                  style={{
                    justifyContent: "flex-start",
                    marginLeft: "16px",
                    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                    flex: 0,
                  }}
                >
                  <Options
                    disableUnderline
                    variant="standard"
                    value={typesOfPickup.name}
                    defaultValue="occPickup"
                    onChange={(e) => {
                      setSelectedPickup(e.target.value);
                      setMonthlyBookings([]);
                    }}
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",

                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "16px",

                      color: "#000000",
                    }}
                  >
                    {typesOfPickup.map((pickup, index) => (
                      <MenuItem
                        style={{ fontFamily: "Roboto" }}
                        value={pickup.value}
                        key={index}
                      >
                        {pickup.name}
                      </MenuItem>
                    ))}
                  </Options>
                </HeaderCard>
              </Stack>
              {viewAllHotelsPickup ? (
                <HeaderCardPickup
                  sx={{
                    paddingRight: "0px !important",
                  }}
                >
                  <ViewButton
                    onClick={() => {
                      history.push(`/hotel/${hotelId}/pickup_all`);
                    }}
                  >
                    View All Hotels
                  </ViewButton>
                </HeaderCardPickup>
              ) : null}
            </SubHeaderNew>
          </Header>

          {!Loading ? (
            monthlyBookings && monthlyBookings.length > 0 ? (
              <>
                <TableCard>
                  {
                    <PaceCalculation
                      uploadImage={uploadImage}
                      sendRemark={sendRemark}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      remarkData={remarkData}
                      setRemarkData={setRemarkData}
                      popoverData={popoverData}
                      setPopOverData={setPopOverData}
                      fromDate={fromDate}
                      toDate={toDate}
                      monthlyBookings={monthlyBookings}
                      selectedPickup={selectedPickup}
                    />
                  }
                </TableCard>
                <Snackbar
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleCloseStatus}
                >
                  <SnackbarContent
                    style={{ backgroundColor: "#CA3433" }}
                    message={networkMsg}
                  />
                </Snackbar>
              </>
            ) : (
              <Nodata />
            )
          ) : (
            <LoadingPage />
          )}
        </ClassWrapper>
      </PacePage>
    ) : !PUReport ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
}

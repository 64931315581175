import { TableCell, TableContainer, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { getDateDDMM, getDateDDMMYYYY, getDay, getHrMin } from "../../sdk";
import {
  DATE,
  DAY,
  MonthCard,
  Table,
  Tbody,
  Thead,
  Tr,
  Wrapper,
} from "./Styles";
import { Box as BX } from "@mui/material";
import Popover from "@mui/material/Popover";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import Draggable from "react-draggable";

const Box = styled(BX)`
  &.MuiBox-root {
    padding: 10px;
  }
`;

export const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    background-color: #306fbc;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;

export const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #212121;

    border: none;
    max-height: 10px;
    padding: 0 16px;
    text-align: center;
    max-width: 100px;
    width: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const MonthHeaderNew = styled(Typography)`
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  padding: 0px 0 8px 0;
`;
export default function PickupCalculation({
  fromDate,
  toDate,
  monthlyBookings,
  selectedPickup,
  uploadImage,
  sendRemark,
  selectedImages,
  setSelectedImages,
  remarkData,
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let noOfLastRows =
    selectedPickup === "occPickup" ? 2 : selectedPickup === "revPickup" ? 1 : 1;
  const [isEditing, setIsEditing] = useState(false);
  const [popOver, setPopOver] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverDate, setPopOverDate] = useState("");
  const [disablePrevDate, setDisablePrevDate] = useState(false);
  const [futureRemark, setFutureRemark] = useState(false);
  const id = !!anchorEl ? "simple-popover" : undefined;

  let previousLength = 0;

  const handleInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    previousLength = newLength;
  };

  const convertImageToBlob = async (image) => {
    const response = await fetch(image.uri);
    const blob = await response.blob();
    return blob;
  };

  const handleImagePreview = async (e) => {
    if (e.target.files) {
      const images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (file.type.startsWith("image")) {
          const image = URL.createObjectURL(file);
          const blob = await convertImageToBlob({ uri: image });
          images.push(blob);
        } else {
          alert("Only image files are allowed.");
          break;
        }
      }
      const imageUrl = await uploadImage(images, popOverDate);
      setSelectedImages((prevState) => [...prevState, ...imageUrl]);
      setPopOver((prevState) => ({
        ...prevState,
        images: [...(prevState?.images || []), ...imageUrl],
      }));
    }
  };

  const handleDeleteImage = (index, deletedImage) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((img, i) => i !== index)
    );
    setPopOver((prevState) => ({
      ...prevState,
      images: prevState.images?.filter(
        (item) => item.imageURL !== deletedImage.imageURL
      ),
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedImages([]);
  };

  const handleSaveChanges = () => {
    setIsEditing(false);
    sendRemark(popOverDate, popOver);
    setAnchorEl(null);
  };

  const handleEditBtn = () => {
    setIsEditing(true);
  };

  const handleDiscard = () => {
    setIsEditing(false);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item?.remark?.date).toLocaleDateString()) ===
        String(new Date(popOverDate).toLocaleDateString())
      );
    });
    setPopOver(data);
    setSelectedImages([]);
  };

  const handleRowClick = (date, e) => {
    setPopOverDate(date);
    const data = remarkData.find((item) => {
      return (
        String(new Date(item?.remark?.date).toLocaleDateString()) ===
        String(new Date(date)?.toLocaleDateString())
      );
    });

    if (data) {
      setPopOver(data);
      setNoData(false);
    } else {
      setPopOver(undefined);
      setNoData(true);
    }
    setAnchorEl(e.currentTarget);
    setDisablePrevDate(
      String(new Date()?.toLocaleDateString()) ===
        String(new Date(date)?.toLocaleDateString())
        ? false
        : new Date() > new Date(date)
    );
    setFutureRemark(new Date() < new Date(date));
  };

  return (
    <>
      <Wrapper>
        {monthlyBookings?.pickUpResponse?.map((monthList, index) => {
          return (
            <MonthCard key={index}>
              <MonthHeaderNew>
                {months[new Date(monthList?.date).getMonth()]}{" "}
                {new Date(monthList?.date).getFullYear()}
              </MonthHeaderNew>
              <TableContainer sx={{ height: "calc(100% - 50px)", width: 480 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{
                    width: "100%",
                    border: "1px solid rgba(0, 0, 0, 0.12)",

                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                  }}
                >
                  <Thead>
                    <Tr>
                      <Th style={{ borderRadius: "4px 0px 0px 0px" }}>Date</Th>
                      <Th>{getDateDDMM(fromDate)}</Th>
                      <Th>{getDateDDMM(toDate)}</Th>
                      <Th
                        style={
                          selectedPickup === "occPickup"
                            ? {}
                            : { borderRadius: "0px 4px 0px 0px" }
                        }
                      >
                        Pickup
                      </Th>
                      {selectedPickup === "occPickup" && (
                        <Th
                          style={{
                            textAlign: "center",
                            borderRadius: "0px 4px 0px 0px",
                          }}
                        >
                          Occ%
                        </Th>
                      )}
                    </Tr>
                  </Thead>

                  <Tbody>
                    {monthList?.output?.map((monthDate, idx) => (
                      <Tr
                        key={idx}
                        style={
                          idx % 2 === 0 ? { backgroundColor: "white" } : {}
                        }
                      >
                        <Td>
                          <DATE>
                            <div
                              onClick={(e) => {
                                handleRowClick(monthDate.date, e);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {getDateDDMM(monthDate.date)}{" "}
                            </div>
                            <DAY>{getDay(monthDate.date)}</DAY>
                          </DATE>
                        </Td>
                        <Td>
                          {monthDate.fromActuals === -1 ? (
                            "-"
                          ) : selectedPickup === "arrPickup" ? (
                            <p
                              style={
                                monthDate.fromActuals < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {" "}
                              {parseFloat(monthDate.fromActuals).toFixed(2)}
                            </p>
                          ) : (
                            <p
                              style={
                                monthDate.fromActuals < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {monthDate.fromActuals}
                            </p>
                          )}
                        </Td>
                        <Td>
                          {monthDate.toActuals === -1 ? (
                            "-"
                          ) : selectedPickup === "arrPickup" ? (
                            <p
                              style={
                                monthDate.toActuals < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {parseFloat(monthDate.toActuals).toFixed(2)}
                            </p>
                          ) : (
                            <p
                              style={
                                monthDate.toActuals < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {monthDate.toActuals}
                            </p>
                          )}
                        </Td>
                        <Td>
                          {monthDate.fromActuals === -1 ||
                          monthDate.toActuals === -1 ? (
                            "-"
                          ) : selectedPickup === "arrPickup" ? (
                            <p
                              style={
                                monthDate.pickup < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {parseFloat(monthDate.pickup).toFixed(2)}
                            </p>
                          ) : (
                            <p
                              style={
                                monthDate.pickup < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {monthDate.pickup}
                            </p>
                          )}
                        </Td>
                        {selectedPickup === "occPickup" && (
                          <Td
                            style={
                              monthDate.occupancyColor
                                ? {
                                    background: `linear-gradient(${monthDate.occupancyColor},#FFFFFF)`,
                                  }
                                : {}
                            }
                          >
                            {monthDate.toOccupancy === -1
                              ? "-"
                              : `${parseFloat(monthDate.toOccupancy).toFixed(
                                  2
                                )}%`}
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                  <Tbody>
                    <Tr
                      style={
                        selectedPickup === "occPickup"
                          ? {
                              backgroundColor: "#306fbc",
                              height: "36px",
                              position: "sticky",
                              bottom:
                                selectedPickup === "occPickup" ? "36px" : "0px",

                              color: "#ffffff",
                            }
                          : {
                              backgroundColor: "#306fbc",
                              height: "36px",
                              position: "sticky",
                              bottom:
                                selectedPickup === "occPickup" ? "36px" : "0px",
                              color: "#ffffff",
                            }
                      }
                    >
                      <Td
                        style={
                          selectedPickup === "occPickup"
                            ? {
                                color: "#ffffff",
                                borderRadius: "0px",
                              }
                            : {
                                color: "#ffffff",
                                borderRadius: "0px 0px 0px 4px",
                              }
                        }
                      >
                        <b>Total</b>
                      </Td>
                      <Td style={{ color: "#ffffff" }}>
                        <b>
                          {monthList?.totalFromActuals === -1
                            ? "-"
                            : selectedPickup === "arrPickup"
                            ? parseFloat(monthList?.totalFromActuals).toFixed(2)
                            : parseInt(monthList?.totalFromActuals)}
                        </b>
                      </Td>
                      <Td style={{ color: "#ffffff" }}>
                        <b>
                          {monthList?.totalToActuals === -1
                            ? "-"
                            : selectedPickup === "arrPickup"
                            ? parseFloat(monthList?.totalToActuals).toFixed(2)
                            : parseInt(monthList?.totalToActuals)}
                        </b>
                      </Td>
                      <Td
                        style={{
                          color: "#ffffff",
                          borderRadius:
                            selectedPickup === "occPickup"
                              ? "0px"
                              : "0px 0px 4px 0px",
                        }}
                      >
                        <b>
                          {monthList?.totalPickup === "NaN" ||
                          monthList?.totalPickup === undefined ? (
                            ""
                          ) : monthList?.totalToActuals === -1 ||
                            monthList?.totalFromActuals === -1 ? (
                            "-"
                          ) : monthList?.totalPickup < 0 ? (
                            <p
                              style={{
                                color: `white`,
                              }}
                            >
                              -(
                              {Math.abs(monthList?.totalPickup)})
                            </p>
                          ) : selectedPickup === "arrPickup" ? (
                            parseFloat(monthList?.totalPickup).toFixed(2)
                          ) : (
                            parseInt(monthList?.totalPickup)
                          )}
                        </b>
                      </Td>
                      {selectedPickup === "occPickup" && <Td></Td>}
                    </Tr>
                  </Tbody>
                  {selectedPickup === "occPickup" ? (
                    <Tbody>
                      <Tr
                        style={{
                          backgroundColor: "#306fbc",
                          height: "36px",
                          position: "sticky",
                          bottom: "0px",
                        }}
                      >
                        <Td style={{ borderRadius: "0px 0px 0px 4px" }}></Td>
                        <Td style={{ color: "#ffffff" }}>
                          <b>
                            {parseFloat(
                              monthList?.fromActualPercentage
                            ).toFixed(2) + "%"}
                          </b>
                        </Td>
                        <Td style={{ color: "#ffffff" }}>
                          <b>
                            {parseFloat(monthList?.toActualPercentage).toFixed(
                              2
                            ) + "%"}
                          </b>
                        </Td>
                        <Td style={{ color: "#ffffff" }}>
                          <b>
                            {parseFloat(
                              monthList?.totalPickUpPercentage
                            ).toFixed(2) + "%"}
                          </b>
                        </Td>
                        <Td style={{ borderRadius: "0px 0px 4px 0px" }}></Td>
                      </Tr>
                    </Tbody>
                  ) : null}
                </Table>
              </TableContainer>
            </MonthCard>
          );
        })}
      </Wrapper>

      <Draggable>
        {noData ? (
          disablePrevDate ? (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <Stack direction={"row"} p={2} gap={4}>
                <Typography>No Remark</Typography>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
              </Stack>
            </Popover>
          ) : futureRemark ? (
            <>
              <div style={{ cursor: "default" }}></div>
            </>
          ) : (
            <Popover
              id={id}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  border: "1px solid #000000",
                  maxWidth: "587px",
                  maxHeight: "435px",
                },
              }}
            >
              <>
                <Stack direction="row" mt={3}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      flexGrow: 1,
                      textAlign: "center",
                      cursor: "move",
                    }}
                  >
                    Remark of{" "}
                    {getDateDDMMYYYY(popOver?.remark?.date ?? popOverDate)}{" "}
                  </Typography>
                  <CloseIcon
                    sx={{ cursor: "pointer", mr: "10px" }}
                    onClick={handleClose}
                  />
                </Stack>

                <Stack direction="row" gap={"141px"} mt={3} ml={3} mr={3}>
                  <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                    Last Edited By: {popOver?.userName}
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                    Last edited Time:{" "}
                  </Typography>
                </Stack>

                <TextField
                  sx={{ ml: 3, mt: 2, mr: 3 }}
                  multiline
                  fullWidth
                  value={popOver?.remark?.remark}
                  onChange={(e) => {
                    setPopOver({
                      ...popOver,
                      remark: { ...popOver?.remark, remark: e.target.value },
                    });
                  }}
                  variant="standard"
                  placeholder="Type your remark..."
                  InputProps={{ disableUnderline: "true" }}
                  onInput={handleInput}
                />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    padding: "1px",
                  }}
                >
                  {selectedImages?.map((img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "100px",
                        marginLeft: "20px",
                      }}
                    >
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "7px",
                          right: "-17px",
                          color: "white",
                          backgroundColor: "#6C7270",
                          borderRadius: "50%",
                          height: "17px",
                          width: "18px",
                        }}
                        onClick={() => handleDeleteImage(index)}
                      />
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  ))}
                </div>

                <Stack direction="row" style={{ display: "flex" }} mt={7} p={2}>
                  <label>
                    <TextField
                      type="file"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ visibility: "hidden" }}
                      variant="standard"
                      onChange={handleImagePreview}
                      multiple
                    />
                    <Stack direction="row">
                      <AddPhotoAlternateIcon
                        onClick={handleImagePreview}
                        sx={{ color: "grey", cursor: "pointer" }}
                      />
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "grey",
                          ml: 1,
                          cursor: "pointer",
                        }}
                      >
                        Add Image
                      </Typography>
                    </Stack>
                  </label>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveChanges}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        alignSelf: "end",
                        fontSize: "14px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </>
                </Stack>
              </>
            </Popover>
          )
        ) : (
          <Popover
            id={id}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPopover-paper": {
                border: "1px solid #000000",
                maxWidth: "587px",
                maxHeight: "712px",
                p: 2,
              },
            }}
          >
            <>
              <Stack direction="row" mt={1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    textAlign: "center",
                    cursor: "move",
                  }}
                >
                  Remark of {getDateDDMMYYYY(popOver?.remark?.date)}{" "}
                </Typography>
                <CloseIcon
                  sx={{ cursor: "pointer", mr: "10px" }}
                  onClick={handleClose}
                />
              </Stack>

              <Stack
                direction="row"
                mt={3}
                ml={1}
                mr={1}
                gap={"141px"}
                justifyContent={"space-between"}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                  Last Edited By: {popOver?.userName ?? ""}{" "}
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                  Last edited Time: {getHrMin(popOver?.remark?.createdAt ?? "")}{" "}
                </Typography>
              </Stack>

              <TextField
                disabled={!isEditing}
                sx={{
                  ml: 1,
                  mt: 2,
                  mr: 1,
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                multiline
                fullWidth
                value={
                  popOver?.remark?.remark === ""
                    ? "   "
                    : popOver?.remark?.remark
                }
                onChange={(e) => {
                  setIsEditing(true);
                  setPopOver({
                    ...popOver,
                    remark: { ...popOver?.remark, remark: e.target.value },
                  });
                }}
                variant="standard"
                placeholder="Type your remark..."
                InputProps={{ disableUnderline: "true" }}
                onInput={handleInput}
              />

              {isEditing ? (
                <label>
                  <TextField
                    type="file"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{ visibility: "hidden" }}
                    variant="standard"
                    onChange={handleImagePreview}
                    multiple
                  />
                  <Stack direction="row" justifyContent={"end"}>
                    <AddPhotoAlternateIcon
                      onClick={handleImagePreview}
                      sx={{ color: "grey", cursor: "pointer" }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "grey",
                        ml: 1,
                        cursor: "pointer",
                      }}
                    >
                      Add Image
                    </Typography>
                  </Stack>
                </label>
              ) : (
                ""
              )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  padding: "1px",
                }}
              >
                {[...(!!popOver?.images?.length ? popOver?.images : [])].map(
                  (img, index) => (
                    <Box
                      mt={3}
                      key={index}
                      style={{
                        position: "relative",
                        height: "260px",
                        width: "500px",
                        // marginLeft: "20px",
                      }}
                    >
                      {isEditing ? (
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "7px",
                            right: "-17px",
                            color: "white",
                            backgroundColor: "#6C7270",
                            borderRadius: "50%",
                            height: "17px",
                            width: "18px",
                          }}
                          onClick={() => handleDeleteImage(index, img)}
                        />
                      ) : (
                        ""
                      )}
                      <img
                        src={img.imageURL}
                        alt="Preview"
                        style={{
                          height: "258px",
                          width: isEditing ? "500px" : "480px",
                          objectFit: "cover",
                          margin: 1,
                        }}
                      />
                    </Box>
                  )
                )}
              </div>

              {isEditing ? (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDiscard}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "14px",
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{
                      mt: 4,
                      borderRadius: 2,
                      textTransform: "none",
                      height: 40,
                      fontSize: "14px",
                    }}
                  >
                    Save Changes
                  </Button>
                </Stack>
              ) : (
                !disablePrevDate && (
                  <Stack direction="row" mt={7} sx={{ justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEditBtn}
                      sx={{
                        mt: 4,
                        borderRadius: 2,
                        textTransform: "none",
                        height: 40,
                        fontSize: "14px",
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                )
              )}
            </>
          </Popover>
        )}
      </Draggable>
    </>
  );
}
